import React, { useEffect, useMemo } from 'react';
import { GlobalUIProvider } from '@/components/GlobalUIProvider';
import { parse } from 'query-string';
import { storeAccessTokens, stores, LOCALE_ASCRIPTION_MAP } from '@@/config';

import { ErrorBoundary } from 'react-error-boundary';
import { CrashError, DeploymentError } from '@/utils/error';
import * as Sentry from '@sentry/gatsby';
import { SWRConfig } from 'swr';
import { ContactEmail } from '@/utils/constants';
import prodConfig from '@@/appConfig/prod';
import { AppContext, setAppContextValue } from './AppProviderContext';
import { PageDataProvider } from '../PageDataProvider';
import { ShopProvider } from '../ShopProvider';
import { UserProvider } from '../UserProvider/UserProvider';

export function AppProvider({ data, location, pageContext, children }) {
  const { app: appFromQuery } = parse(location.search);

  const appContextValue = useMemo(() => {
    if (appFromQuery && appFromQuery !== pageContext.app) {
      // 优先app参数判断
      let store = stores.find((item) => item.app === appFromQuery || item?.appAlias?.includes(appFromQuery));

      if (!store) {
        store = stores.find((item) => item.brand === pageContext.brand && item.country === 'us') || stores[0];
      }

      return {
        app: store.app,
        language: store.lang,
        brand: process.env.BRAND,
        store: store.domain,
        locale: store.locale || store.country,
        defaultLocale: 'us',
        locales: pageContext.locales,
        storeAccessToken: storeAccessTokens[LOCALE_ASCRIPTION_MAP[store.country] || store.country],
        pathname: location.pathname,
        pathPrefix: store.country !== 'us' ? `/${store.locale || store.country}/` : '/',
        isApp: store.type === 'app',
        location,
        shopifyDomain: store.shopifyDomain,
      };
    }
    return pageContext;
  }, [pageContext, location, appFromQuery]);

  setAppContextValue(appContextValue);

  useEffect(() => {
    if (
      process.env.ENVIRONMENT === 'development' &&
      prodConfig.find((store) => store.domain === window.location.origin)
    ) {
      Sentry.captureException(new DeploymentError(appContextValue.app), {
        tags: { app: appContextValue.app, errorType: 'DeploymentError', errorScope: 'Build' },
      });
    }
  }, [appContextValue]);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
        errorRetryCount: 0,
      }}
    >
      <AppContext.Provider value={appContextValue}>
        <ErrorBoundary
          fallback={
            <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 100, maxWidth: 800, margin: '0 auto' }}>
              <div style={{ fontSize: 24, fontWeight: 600 }}>We’re Sorry for the Inconvenience</div>
              <div style={{ marginTop: 10, fontSize: 18 }}>
                It seems there’s been an issue with our website. Please try again after 15 minutes. If the problem
                persists, contact us at &nbsp;
                <a style={{ color: '#03A9F4' }} href={`mailto:${ContactEmail[process.env.BRAND]}`}>
                  {ContactEmail[process.env.BRAND]}
                </a>
                &nbsp;for assistance. Thank you for your patience!
              </div>
            </div>
          }
          onError={(error) => {
            Sentry.captureException(new CrashError(error.message), {
              tags: {
                app: appContextValue.app,
                errorType: 'CrashError',
                errorScope: 'Client',
              },
            });
          }}
        >
          <UserProvider location={location}>
            <ShopProvider app={appContextValue.app} storeAccessToken={appContextValue.storeAccessToken}>
              <PageDataProvider
                location={location}
                pageContext={appContextValue}
                language={appContextValue.language}
                data={data}
              >
                <GlobalUIProvider>{children}</GlobalUIProvider>
              </PageDataProvider>
            </ShopProvider>
          </UserProvider>
        </ErrorBoundary>
      </AppContext.Provider>
    </SWRConfig>
  );
}
