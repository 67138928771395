import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './styles.module.scss';

function ToastContainer({ children, timeout = 0, onDismiss }) {
  const [show, setShow] = useState(false);
  const [countTime, setCountTme] = useState(timeout);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountTme(countTime - 100);
    }, 100);

    if (countTime <= 0) {
      onDismiss();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [onDismiss, countTime]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div role="status" className={classNames(styles.toastContainer, show ? styles.show : '')}>
      {children}
    </div>
  );
}

ToastContainer.propTypes = {
  children: PropTypes.node,
  onDismiss: PropTypes.func,
  timeout: PropTypes.number,
};

export default ToastContainer;
