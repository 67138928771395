// 页面崩溃错误
export class CrashError extends Error {
  constructor(name: string, message: string, ...args: any[]) {
    super(message, ...args);
    this.name = `CrashError: ${name}`;
  }
}

// 线上发布测试环境包错误
export class DeploymentError extends Error {
  constructor(name: string, message: string, ...args: any[]) {
    super(message, ...args);
    this.name = `DeploymentError: ${name}`;
  }
}
