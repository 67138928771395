import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import ToastContainer from './Container';
import * as styles from './styles.module.scss';

const DEFAULT_TIMEOUT = 3000;

const ToastContext = createContext({
  add(content, timeout) {},
});
const { Consumer, Provider } = ToastContext;

function generateId() {
  return Date.now() + Math.random();
}

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);
  const hasToasts = useMemo(() => toasts.length > 0, [toasts]);

  const add = useCallback((content, timeoutArg = DEFAULT_TIMEOUT) => {
    if (!content) {
      return;
    }
    const newId = generateId();
    let timeout = timeoutArg;
    if (content.length >= 60 && !content.startsWith('<')) {
      timeout = content.length * 25;
    }

    setToasts([
      {
        id: newId,
        content,
        timeout,
      },
    ]);
  }, []);

  const remove = useCallback(
    (id) => {
      const newToasts = toasts.filter((item) => item.id !== id);
      setToasts(newToasts);
    },
    [toasts],
  );

  return (
    <Provider
      value={{
        add,
      }}
    >
      {children}
      {hasToasts &&
        createPortal(
          <Consumer>
            {() => (
              <div className={styles.wrapper}>
                {toasts.map((item) => (
                  <ToastContainer
                    timeout={item.timeout}
                    key={item.id}
                    onDismiss={() => {
                      remove(item.id);
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    />
                  </ToastContainer>
                ))}
              </div>
            )}
          </Consumer>,
          document.body,
        )}
    </Provider>
  );
}
ToastProvider.propTypes = {
  children: PropTypes.node,
};

export function ToastConsumer({ children }) {
  return <Consumer>{(context) => children(context)}</Consumer>;
}
ToastConsumer.propTypes = {
  children: PropTypes.node,
};

export const useToast = () => {
  const ctx = useContext(ToastContext);
  return {
    addToast: ctx.add,
  };
};
