/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
const { Buffer } = require('buffer');
const { PLUS_MEMBER_CONFIG } = require('../../../appConfig/plusMember');

const { stores, LOCALE_ASCRIPTION_MAP } = require('../../../config');
const { Logger } = require('./logger');
const { fetch } = require('./fetch');
const NeedRecaptchaInterfaces = require('../../../api/check-list');

const scoreLimit = JSON.parse(process.env.GOOGLE_RECAPTCHA_SCORE_LIMIT || '{}');

const COUNTRY_MAP = {
  'eu-en': 'eu',
  'eu-de': 'de',
  'eu-fr': 'fr',
  'eu-it': 'it',
  'eu-nl': 'nl',
  'eu-es': 'es',
  'app-eu': 'eu',
};

const getStoreFromParams = ({ app }) => {
  let store = stores.find((item) => item.app === app || item?.appAlias?.includes(app));
  if (!store) {
    store = stores.find((item) => item.brand === process.env.BRAND && item.country === 'us') || stores[0];
  }
  return store;
};

const getAppFromParams = ({ app }) => {
  return getStoreFromParams({ app })?.app;
};

// get app name from event info
const getApp = (event) => {
  //  get 方法从 query string 取, 剩余方法从 body 取
  const method = event.httpMethod.toLowerCase();
  const app = method === 'get' || method === 'delete' ? event.queryStringParameters.app : JSON.parse(event.body).app;
  return getAppFromParams({ app });
};

const getStore = (event) => {
  //  get 方法从 query string 取, 剩余方法从 body 取
  const method = event.httpMethod.toLowerCase();
  const app =
    method === 'get' || method === 'delete'
      ? event.queryStringParameters.app
      : JSON.parse(event.body || '{}').app || event.queryStringParameters.app;

  const store = stores.find((item) => item.app === app || item?.appAlias?.includes(app)) || stores[0];

  return {
    ...store,
    country: COUNTRY_MAP[store.country] || store.country,
  };
};

const fromEu = (app) => {
  const eu_countries = process.env.EU_COUNTRIES.split('|');
  const country = stores.find((store) => store.app === app)?.country || 'us';
  if (eu_countries.includes(LOCALE_ASCRIPTION_MAP[country] || eu_countries.includes(country))) {
    return true;
  }
  return false;
};

const getIpFromHeaders = (headers) => {
  const remoteip =
    headers['anker-client-ip'] ||
    headers['x-shopify-client-ip'] ||
    headers['cf-connecting-ip'] ||
    headers['x-nf-client-connection-ip'] ||
    (headers['x-forwarded-for'] || '').split(', ')[0];

  return remoteip;
};

// return basic response.
const baseResponse = (result) => ({
  statusCode: result.status,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(result),
});

const checkRecaptcha = async (event, token) => {
  const logger = new Logger({ label: 'checkRecaptcha' });
  const { headers, httpMethod } = event;
  if (httpMethod === 'GET' || httpMethod === 'DELETE') {
    return { success: true };
  }
  const remoteip = getIpFromHeaders(headers);

  let secret = process.env.GOOGLE_RECAPTCHA_SECRET;
  if (process.env.GOOGLE_RECAPTCHA_MAP) {
    const siteKey = headers['x-recaptcha-sitekey'];
    const map = JSON.parse(process.env.GOOGLE_RECAPTCHA_MAP);
    if (map[siteKey]) {
      secret = map[siteKey];
    }
  }

  const url = 'https://www.google.com/recaptcha/api/siteverify';
  const requestParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `secret=${secret}&response=${token}&remoteip=${remoteip}`,
  };

  try {
    const response = await fetch(url, requestParams);
    const result = await response.json();

    logger.info('checkRecaptcha result', result);

    if (result.success) {
      const limit = scoreLimit[result.action] || 0.2;
      if (result.score < Number(limit)) {
        return { status: 400, error: 'google-recaptcha-error' };
      }
      return { status: response.status, ...result };
    }
    return { status: 400, error: result['error-codes'].join() };
  } catch (error) {
    logger.error('google-recaptcha-error');
    return { status: 400, error: 'google-recaptcha-error' };
  }
};

const recaptchaError = async (event) => {
  if (event.headers['user-agent']?.includes('Anker-Auto-Test')) {
    return null;
  }

  if (event?.headers?.host && event.headers.host.startsWith('localhost')) {
    return null;
  }

  // google recaptcha 验证是否失败，失败返回失败 response，没失败返回 null
  const token = event.headers.recaptcha_token || event.headers.recaptcha || event.headers['x-recaptcha-token'];

  // 检查 NeedRecaptchaInterfaces 中是否包含 event.path，考虑到相同可访问地址存在 / 结尾的情况
  // start_ai_generated
  const needCheck =
    process.env.ENVIRONMENT !== 'development' &&
    NeedRecaptchaInterfaces.find((item) => `${item}/` === event.path || item === event.path);
  // end_ai_generated

  if (needCheck) {
    if (!token) {
      return {
        statusCode: 403,
        body: JSON.stringify({ error: 'recaptcha_token needed' }),
      };
    }
    const verified = await checkRecaptcha(event, token);
    if ('error' in verified) {
      return baseResponse(verified);
    }
  }
  return null;
};

const parseDomain = (url) => {
  if (url.includes('localhost')) return 'localhost';
  if (url.includes('127.0.0.1')) return '127.0.0.1';
  if (!url) return '';
  let str = url;
  if (str.indexOf('://') !== -1) str = str.substr(str.indexOf('://') + 3);
  const topLevel = [
    'com',
    'net',
    'org',
    'gov',
    'edu',
    'mil',
    'biz',
    'name',
    'info',
    'mobi',
    'pro',
    'travel',
    'museum',
    'int',
    'areo',
    'post',
    'rec',
  ];
  const domains = str.split('.');
  if (domains.length <= 1) return str;
  if (!domains[domains.length - 1]) return str;
  let result = '';
  topLevel.some((v) => {
    if (domains[domains.length - 1] === v) {
      result = `${domains[domains.length - 2]}.${domains[domains.length - 1]}`;
      return true;
    }
    if (domains[domains.length - 2] === v) {
      result = `${domains[domains.length - 3]}.${domains[domains.length - 2]}`;
      return true;
    }
    return false;
  });

  if (!result) {
    result = `${domains[domains.length - 3]}.${domains[domains.length - 2]}.${domains[domains.length - 1]}`;
  }
  return result;
};

const checkDomain = (url) => {
  try {
    const { host } = new URL(url);
    return process.env.REDIRECT_DOMAINS.split('|').some((item) => host.endsWith(item));
  } catch (e) {
    return false;
  }
};

// I returns the parsed body payload.
// --
// CAUTION: Throws error if body cannot be parsed as JSON.
const parseBody = (body, isBase64Encoded) => {
  const fromBase64 = (encodedValue) => Buffer.from(encodedValue, 'base64').toString('utf8');
  const normalizedBody = isBase64Encoded ? fromBase64(body) : body;
  return JSON.parse(normalizedBody);
};

const getCookies = (app, data, { expires, domain } = {}) => {
  const cookieExpires = expires || new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toUTCString();
  const commonCookies = [`Expires=${cookieExpires}`, 'Path=/', 'HttpOnly', 'Secure', 'SameSite=None'];

  if (domain) {
    commonCookies.push(`Domain=${domain}`);
  }

  // 接口(api)返回的 token 名跟要设置的 cookie 名不一致
  // 这是 tokenName -> cookieName 后缀的 mapping 表
  const tokenNames = {
    auth_token: '-token',
    email_auth_token: '-email-token',
    user_id: '-euid',
  };
  // 从接口返回的 tokens 到 http header cookies
  // 筛选过滤返回值中有的 token -> 映射 token 名给 cookie -> 添加相同的 cookiePolicies
  return Object.entries(tokenNames)
    .filter(([tokenName]) => tokenName in data)
    .map(([tokenName, cookieName]) => `${app}${cookieName}=${data[tokenName] || ''}`)
    .map((tokenPair) => [tokenPair, ...commonCookies].join(';'));
};

const removeIdentityCookies = (app, domain) => {
  const expires = new Date(Date.now() - 1).toUTCString();
  const commonCookies = [`Expires=${expires}`, 'Path=/', 'HttpOnly', 'Secure', 'SameSite=None'];
  if (domain) {
    commonCookies.push(`Domain=${domain}`);
  }
  const authCookieNames = [
    `${app}-token`,
    `${app}-info`,
    `${app}-email-token`,
    `${app}-euid`,
    `${app}-customer-token`,
    `${app}-is-login`,
  ];
  return authCookieNames.map((cookieName) => [`${cookieName}=`, ...commonCookies].join(';'));
};

const isEUCountry = (event) => {
  const { httpMethod, headers, queryStringParameters } = event;

  let locale;
  let app;
  let mainHost;

  // start_ai_generated
  if (httpMethod === 'GET' || httpMethod === 'DELETE') {
    app = queryStringParameters.app;
  } else if (httpMethod === 'POST' || httpMethod === 'PUT') {
    if (
      headers['content-type'] &&
      (headers['content-type'].includes('json') || headers['content-type'].includes('text/plain'))
    ) {
      try {
        app = JSON.parse(event.body || '{}').app;
      } catch (err) {
        console.error('parse app error', err);
      }
    }
  }
  // end_ai_generated

  if (app) {
    const store = getStoreFromParams({ app });
    locale = store.country;
  } else if (headers['x-shop-domain']) {
    [app, mainHost] = headers['x-shop-domain'].split('.');
  } else if (queryStringParameters.shopify_domain) {
    [app, mainHost] = queryStringParameters.shopify_domain.split('.');
  } else if (queryStringParameters.shop) {
    [app, mainHost] = queryStringParameters.shop.split('.');
  } else if (headers.referer) {
    const refererURL = new URL(headers.referer);
    [app, mainHost] = refererURL.hostname.split('.');
  }

  if (mainHost === 'myshopify') {
    // xxx.myshopify.com
    const store = getStoreFromParams({ app });
    locale = store.country;
  }

  if (app !== 'www') {
    if (!locale) {
      locale = app;
    }
  }

  const euCountries = process.env.EU_COUNTRIES.split('|');

  return euCountries.includes(LOCALE_ASCRIPTION_MAP[locale] || locale);
};

const isPlusBeta = (shopName) => {
  return ['beta-eufy-us-plus.myshopify.com', 'beta-soundcore.myshopify.com'].includes(shopName);
};

const isSupportPlusMember = ({ brand, locale }) => {
  return Boolean(PLUS_MEMBER_CONFIG[brand]?.countryList?.find((country) => country === locale));
};

const regExp = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
const validEmail = (val) => regExp.email.test(val);

module.exports = {
  COUNTRY_MAP,
  parseDomain,
  checkDomain,
  recaptchaError,
  parseBody,
  baseResponse,
  getApp,
  getAppFromParams,
  getStore,
  fromEu,
  getCookies,
  removeIdentityCookies,
  getStoreFromParams,
  isEUCountry,
  isPlusBeta,
  getIpFromHeaders,
  isSupportPlusMember,
  validEmail,
};
