import { LOCALE_ASCRIPTION_MAP } from '@@/config';
import GrayPC from '@@/static/icons/coupon/gray-pc.png';
import UsedPC from '@@/static/icons/coupon/used-pc.png';
import ExpiredPC from '@@/static/icons/coupon/expired-pc.png';
import GrayMobile from '@@/static/icons/coupon/gray-mobile.png';
import UsedMobile from '@@/static/icons/coupon/used-mobile.png';
import ExpiredMobile from '@@/static/icons/coupon/expired-mobile.png';

export const DEFAULT_PRODUCT_IMAGE = 'https://cdn.shopify.com/s/files/1/0504/7094/4954/files/2021315-175221.jpeg';

export const ORDER_STATUS_OLD = {
  cancelled: 1,
  partiallyRefunded: 2,
  refunded: 3,
  fulfilled: 4,
  partial: 5,
  processing: 6,
};

export const ORDER_STATUS = {
  cancelled: { color: '#999', id: 'cancelled' },
  partiallyRefunded: { color: '#F84D4F', id: 'partiallyRefunded' },
  refunded: { color: '#F84D4F', id: 'refunded' },
  shipped: { color: '#52C41A', id: 'shipped' },
  partially: { color: '#52C41A', id: 'partially' },
  processing: { color: '#FAAD15', id: 'processing' },
};

export const ORDER_RETURN_STATUS = {
  cancelled: { color: '#999', key: 'cancelled' },

  rejected: { color: '#F84D4F', key: 'rejected' },
  delayed: { color: '#F84D4F', key: 'delayed' },
  refund_pending: { color: '#F84D4F', key: 'refund_pending' },

  submitted: { color: '#FAAD15', key: 'submitted' },
  approved: { color: '#FAAD15', key: 'approved' },
  shipped: { color: '#FAAD15', key: 'shipped' },
  delivered: { color: '#FAAD15', key: 'delivered' },
  received: { color: '#FAAD15', key: 'received' },
  return_recovered: { color: '#FAAD15', key: 'return_recovered' },

  ticket_created: { color: '#38C74F', key: 'ticket_created' },
  refunded: { color: '#38C74F', key: 'refunded' },
};

export const brandColor = {
  anker: '#00a7e1',
  ankersolix: '#00a7e1',
  eufy: '#005D8E',
  soundcore: '#17bbef',
  ankerwork: '#007dff',
  ankermake: '#88f387',
  nebula: '#d71c35',
  mach: '#000',
};

export const CountryMap = {
  us: 'us',
  uk: 'uk',
  'eu-en': 'eu',
  'eu-de': 'de',
  'eu-fr': 'fr',
  'eu-it': 'it',
  'eu-nl': 'nl',
  'eu-es': 'es',
  fr: 'fr',
  ca: 'ca',
  nl: 'nl',
  'app-eu': 'eu',
};

export const LocaleToNumericDateFormatMap = {
  us: 'MM/DD/YYYY',
  uk: 'DD/MM/YYYY',
  'eu-en': 'DD.MM.YYYY',
  'eu-de': 'DD.MM.YYYY',
  'eu-fr': 'DD -MM -YYYY',
  'eu-it': 'DD.MM.YYYY',
  'eu-nl': 'DD.MM.YYYY',
  fr: 'DD -MM -YYYY',
  ca: 'YYYY/MM/DD',
};

export const LocaleToAbbreviatedDateFormatMap = {
  us: 'MMM DD, YYYY',
  uk: 'DD MMM YYYY',
  'eu-en': 'DD MMM YYYY',
  'eu-de': 'DD MMM YYYY',
  'eu-fr': 'DD MMM YYYY',
  'eu-it': 'DD MMM YYYY',
  'eu-nl': 'DD MMM YYYY',
  fr: 'DD MMM YYYY',
  ca: 'MMM DD, YYYY',
};

export const CountryCodeMap = {
  us: 'US',
  uk: 'GB',
  'eu-en': '',
  'eu-de': 'DE',
  'eu-fr': 'FR',
  'eu-it': 'IT',
  'eu-nl': 'NL',
  fr: 'FR',
  ca: 'CA',
  'eu-es': 'ES',
  'app-eu': '',
};

export const languageTerritoryMapping = {
  us: 'en-US',
  uk: 'en-GB',
  ca: 'en-CA',
  fr: 'fr-FR',
  au: 'en-AU',
  'eu-de': 'de-DE',
  'eu-en': 'en-DE',
  'eu-nl': 'nl-NL',
  'eu-es': 'es-ES',
};

export const currencyCodeMap = {
  us: 'USD',
  uk: 'GBP',
  ca: 'CAD',
  fr: 'EUR-FR',
  au: 'AUD',
  de: 'EUR',
  eu: 'EUR',
  'eu-de': 'EUR',
  'eu-en': 'EUR',
  'eu-nl': 'EUR',
  'eu-fr': 'EUR-FR',
  'eu-es': 'EUR',
  'eu-it': 'EUR',
};

Object.entries(LOCALE_ASCRIPTION_MAP).forEach(([key, value]) => {
  const [countryCode] = key.split('-');
  CountryMap[key] = countryCode;
  CountryCodeMap[key] = countryCode.toLocaleUpperCase();
  languageTerritoryMapping[key] = languageTerritoryMapping[value];
  currencyCodeMap[key] = currencyCodeMap[value];
});

export const CouponBg = {
  available: GrayPC,
  used: UsedPC,
  expired: ExpiredPC,
};

export const MobileCouponBg = {
  available: GrayMobile,
  used: UsedMobile,
  expired: ExpiredMobile,
};

export const BrandName = {
  anker: 'Anker',
  soundcore: 'soundcore',
  eufy: 'eufy',
  nebula: 'Nebula',
  ankerwork: 'AnkerWork',
  ankermake: 'AnkerMake',
  ankersolix: 'Anker',
};

export const ContactEmail = {
  anker: 'support@anker.com',
  eufy: 'support@eufy.com',
  soundcore: 'service@soundcore.com',
  nebula: 'support@seenebula.com',
  ankerwork: 'support@ankerwork.com',
  ankermake: 'support@ankermake.com',
  ankersolix: 'support@anker.com',
};

export const CreditName = {
  anker: 'AnkerCredits',
  eufy: 'eufyCredits',
  soundcore: 'soundcoreCredits',
  nebula: 'NEBULA Credits',
};
