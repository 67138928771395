import { navigate as gatsbyNavigate } from 'gatsby';
import { stringifyUrl } from 'query-string';
import { useCallback } from 'react';
import { useAppContext } from './useApp';

// start_ai_generated
/**
 * 自定义 useRouter 钩子，提供导航功能和和当前路由的上下文信息
 * @typedef {Object} RouterHook
 * @property {string} locale - 当前语言环境
 * @property {string} defaultLocale - 默认语言环境
 * @property {boolean} isDefaultLocale - 是否为默认语言环境
 * @property {Function} navigate - 导航函数，用于页面跳转
 * @returns {RouterHook} 返回包含导航和语言环境信息的对象
 */
// end_ai_generated
export const useRouter = () => {
  const { locales, location, locale, defaultLocale, pathPrefix } = useAppContext();

  const navigate = useCallback(
    (path, query, options) => {
      if (typeof path === 'number') {
        return gatsbyNavigate(path, options);
      }
      if (typeof path === 'string') {
        if (path.startsWith('/')) {
          if (locales.find((item) => path.startsWith(`/${item}`))) {
            return gatsbyNavigate(
              stringifyUrl({
                url: path,
                query,
              }),
              options,
            );
          }

          return gatsbyNavigate(
            stringifyUrl({
              url: pathPrefix + path.slice(1),
              query,
            }),
            options,
          );
        }
        if (path.startsWith('http') || path.startsWith('//')) {
          if (options?.replace) {
            return window.location.replace(
              stringifyUrl({
                url: path,
                query,
              }),
            );
          }
          window.location.href = stringifyUrl({
            url: path,
            query,
          });
          return Promise.resolve();
        }
        return gatsbyNavigate(
          stringifyUrl({
            url: pathPrefix + path,
            query,
          }),
          options,
        );
      }

      return Promise.resolve();
    },
    [pathPrefix, locales],
  );

  return {
    location,
    locale,
    defaultLocale,
    isDefaultLocale: locale === defaultLocale,
    navigate,
  };
};
