import { navigateTo } from '@/utils/helper';

const fetcher = async ({
  url,
  app,
  method = 'GET',
  body,
  headers,
}: {
  url: string;
  app: string;
  method?: 'GET' | 'POST';
  body?: { [key: string]: any };
  headers?: { [key: string]: any };
  logout?: () => void;
  // eslint-disable-next-line consistent-return
}) => {
  const response = await fetch(url, {
    method,
    headers: {
      ...(headers && { ...headers }),
    },
    ...(method === 'POST' && {
      body: JSON.stringify({
        brand: process.env.BRAND === 'ankersolix' ? 'anker' : process.env.BRAND,
        app,
        ...(body && { ...body }),
      }),
    }),
  });
  const data = await response.json();
  if (response.status === 401) {
    navigateTo(`/`, {
      redirect: window.location.href,
    });
  } else {
    return data;
  }
};

export const swrConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

export default fetcher;
