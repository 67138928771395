import React from 'react';

const defaultValue = {
  // 品牌名 anker/soundcore/eufy/...
  brand: process.env.BRAND,
  // 语言 en/de/fr/nl/it/...
  language: '',
  // 店铺首页
  store: '',
  // 店铺shopify域名前缀 ankerus/soundcoreusa/eufy-us/...
  app: '',
  // 地区 us/uk/eu-en/eu-de/fr/eu-fr...
  locale: '',
  defaultLocale: '',
  pathPrefix: '/',
  locales: [],
  // 店铺 storefront api access token
  storeAccessToken: '',
  location: null,
  // 来源类型 app 或者 website
  isApp: false,
  shopifyDomain: '',
};

export const AppContext = React.createContext({
  ...defaultValue,
});

let appContextValue = {
  ...defaultValue,
};

export const getAppContextValue = () => {
  return appContextValue;
};

export const setAppContextValue = (newValue) => {
  appContextValue = {
    ...appContextValue,
    ...newValue,
  };
};
