import { AUTO_SIGNUP_CONFIG } from '@@/appConfig/autoSignup';
import { CountryMap } from './constants';

export const isSupportAutoSignUp = ({ brand, locale }: { brand: string; locale: string }): boolean => {
  const brandConfig = AUTO_SIGNUP_CONFIG[brand];
  if (brandConfig) {
    if (brandConfig.country) {
      return brandConfig.country.includes(CountryMap[locale] || locale);
    } else if (brandConfig.excludeCountry) {
      return !brandConfig.excludeCountry.includes(CountryMap[locale] || locale);
    } else {
      return true;
    }
  }

  return false;
};

// 订注一体流程优化，不中断用户注册流程，直接弹出激活弹窗，用户可以选择激活或者跳过
export const isSupportPopActivateModal = ({ brand }: { brand: string }): boolean => {
  const brandConfig = AUTO_SIGNUP_CONFIG[brand];
  return brandConfig?.popActivateModal as boolean;
};
