const LOCALE_ASCRIPTION_MAP = {
  'cz-en': 'eu-en',
  'dk-en': 'eu-en',
  'gr-en': 'eu-en',
  'mk-en': 'eu-en',
  'hu-en': 'eu-en',
  'se-en': 'eu-en',
  'hr-en': 'eu-en',
  'bg-en': 'eu-en',
  'ee-en': 'eu-en',
  'fi-en': 'eu-en',
  'lt-en': 'eu-en',
  'pl-en': 'eu-en',
  'ro-en': 'eu-en',
  'si-en': 'eu-en',
  'sk-en': 'eu-en',
  'no-en': 'eu-en',
  'ad-en': 'eu-en',
  'al-en': 'eu-en',
  'ba-en': 'eu-en',
  'me-en': 'eu-en',
  'rs-en': 'eu-en',
  'gl-en': 'eu-en',
  'is-en': 'eu-en',
  'ua-en': 'eu-en',
  'by-en': 'eu-en',
  'md-en': 'eu-en',
  'lv-en': 'eu-en',
  'cy-en': 'eu-en',
  'mt-en': 'eu-en',
  'at-de': 'eu-de',
  'lu-de': 'eu-de',
  'li-de': 'eu-de',
  'ch-de': 'eu-de',
  'va-it': 'eu-it',
  'sm-it': 'eu-it',
  'be-nl': 'nl',
};

const devEufyEuApps = Object.entries(LOCALE_ASCRIPTION_MAP).map(([key, value]) => {
  const [locale, language] = key.split('-');
  const [targetLocale, targetLanguage] = value.split('-');

  const shopifyDomain =
    targetLocale === 'eu' && targetLanguage === 'en'
      ? 'beta-eufy-eu.myshopify.com'
      : `beta-eufy-${targetLanguage || targetLocale}.myshopify.com`;

  const [shopifyApp] = shopifyDomain.split('.');

  return {
    app: `beta-eufy-${locale}`,
    shopifyApp,
    domain: `https://beta.eufy.com/${key}`,
    shopifyDomain,
    brand: 'eufy',
    country: key,
    lang: language,
  };
});

const prodEufyEuApps = Object.entries(LOCALE_ASCRIPTION_MAP).map(([key, value]) => {
  const [locale, language] = key.split('-');
  const [targetLocale, targetLanguage] = value.split('-');

  const shopifyDomain =
    targetLocale === 'eu' && targetLanguage === 'en'
      ? 'eufy-eu.myshopify.com'
      : `eufy-${targetLanguage || targetLocale}.myshopify.com`;

  const themeDomain = `${locale}.eufy.com`;

  const [shopifyApp] = shopifyDomain.split('.');

  return {
    app: `eufy-${locale}`,
    shopifyApp,
    domain: `https://www.eufy.com/${key}`,
    shopifyDomain,
    themeDomain,
    brand: 'eufy',
    country: key,
    lang: language,
  };
});

const DEV_APP_ASCRIPTION_MAP = {};
devEufyEuApps.forEach((item) => {
  DEV_APP_ASCRIPTION_MAP[item.app] = item.shopifyApp;
});

const PROD_APP_ASCRIPTION_MAP = {};
prodEufyEuApps.forEach((item) => {
  PROD_APP_ASCRIPTION_MAP[item.app] = item.shopifyApp;
});

const APP_ASCRIPTION_MAP = process.env.ENVIRONMENT === 'production' ? PROD_APP_ASCRIPTION_MAP : DEV_APP_ASCRIPTION_MAP;

module.exports = {
  eufyEuApps: process.env.ENVIRONMENT === 'production' ? prodEufyEuApps : devEufyEuApps,
  devEufyEuApps,
  prodEufyEuApps,
  LOCALE_ASCRIPTION_MAP,
  APP_ASCRIPTION_MAP,
};
