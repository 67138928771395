import { getShopInfo } from '@/services/storefrontApi';
import React, { useEffect, useState } from 'react';

const defaultValue = {
  shop: {},
  shopMetafields: {},
};

export const ShopContext = React.createContext(defaultValue);

export function ShopProvider({ app, storeAccessToken, children }) {
  const [isLoading, setLoading] = useState(true);
  const [shop, setShop] = useState(defaultValue);

  useEffect(() => {
    const getData = async () => {
      if (!storeAccessToken) {
        setShop({
          shop: {},
          shopMetafields: {},
        });
        return;
      }

      setLoading(true);
      try {
        const res = await getShopInfo({ app, storeAccessToken });
        setShop(res);
      } catch (err) {
        console.error('get shop info', err);
        setShop({
          shop: {},
          shopMetafields: {},
        });
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [app, storeAccessToken]);

  return (
    <ShopContext.Provider
      value={{
        ...shop,
        isLoading,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
}
