import React, { useMemo } from 'react';
import { HeadContainer } from '@/components/HeadContainer';
import { ToastProvider } from '../Toast';
import { GlobalUIContext } from './GlobalUIProviderContext';

export function GlobalUIProvider({ children, props }) {
  const [cookieConsent, setCookieConsent] = React.useState(undefined);

  const globalUIContextValue = useMemo(() => {
    return {
      cookieConsent,
      setCookieConsent,
    };
  }, [cookieConsent]);

  return (
    <GlobalUIContext.Provider value={globalUIContextValue}>
      <ToastProvider>
        <HeadContainer />
        {children}
      </ToastProvider>
    </GlobalUIContext.Provider>
  );
}
