import { getRecaptchaHeaders } from '@/utils';

const fetcher = async ({
  url,
  app,
  method = 'GET',
  body,
  headers,
  needRecaptcha = false,
  recaptchaAction = '',
}: {
  url: string;
  app?: string;
  method?: 'GET' | 'POST';
  body?: { [key: string]: any };
  headers?: { [key: string]: any };
  needRecaptcha?: boolean;
  recaptchaAction?: string;
}) => {
  let recaptchaHeaders = {};
  if (needRecaptcha) {
    recaptchaHeaders = await getRecaptchaHeaders(recaptchaAction, {
      recaptchaHeaderKey: 'recaptcha',
    });
  }

  const response = await fetch(url, {
    method,
    headers: {
      ...(headers && { ...headers }),
      ...recaptchaHeaders,
    },
    ...(method === 'POST' && {
      body: JSON.stringify({
        brand: process.env.BRAND,
        app,
        ...(body && { ...body }),
      }),
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    const error = new Error('An error occurred while fetching the data.');
    throw error;
  }
  return data;
};

export const swrConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export default fetcher;
