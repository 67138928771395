/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://5a9c0a32d890d344f661e39482fc4d18@o487797.ingest.us.sentry.io/4506012408676352',
  environment: process.env.ENVIRONMENT,
  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],

  tracesSampleRate: 0,
  tracesSampler() {
    return 0;
  },

  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    // Crash Error & Deployment Error
    const isDeploymentError = String(event.tags?.errorType) === 'DeploymentError';
    const isCrashError = String(event.tags?.errorType) === 'CrashError' && process.env.ENVIRONMENT === 'production';

    if (isDeploymentError || isCrashError) {
      return { ...event, tags: { ...event.tags, brand: process.env.BRAND } };
    } else {
      // Runtime Click error

      const unWantedValues = [
        "Unexpected token ':'",
        'unexpected token: ":"',
        'Retry count of 5 exceeded',
        'The operation is not supported',
        'Blocked a frame with origin',
        'load error:',
        'Loading chunk',
        'Load failed',
        'The operation is insecure',
        'Failed to load bugsnag',
        'ADMITAD',
      ];
      const errorFiltered = event.exception?.values?.some(({ value }) => unWantedValues.find((v) => v === value));
      const errorWantToUpload = event.exception?.values?.some((value) => {
        const lastFrame = value.stacktrace?.frames?.[(value.stacktrace?.frames?.length || 0) - 1];

        return (
          lastFrame?.in_app &&
          lastFrame.filename &&
          lastFrame.filename !== '<anonymous>' &&
          !lastFrame.filename.includes('node_modules') &&
          !lastFrame.filename.includes('src/client/script') &&
          value.stacktrace?.frames?.some((frame) => frame.function === 'onClick')
        );
      });
      if (event.tags?.errorScope === 'Client') {
        if (errorFiltered) {
          return null;
        } else if (errorWantToUpload) {
          return {
            ...event,
            tags: { ...event.tags, brand: process.env.BRAND, errorScope: 'Client', errorType: 'RuntimeClickError' },
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  },
});
