import React from 'react';
import useSWR from 'swr';
import Cookies from 'js-cookie';
import { useAppContext } from '@/hooks/useApp';
import { fetchProfile } from '@/services/identity';
import usePlusMember from '@/hooks/usePlusMember';
import { parse } from 'query-string';
import { Profile } from '@/types/response/user';
import { parseDomain } from '@/utils';
import { UserContext } from './UserContext';

export function UserProvider({ location, children }) {
  const { app } = useAppContext();

  // 用户中心 profile
  const user = useSWR<Profile>('user', () => {
    const isLogin = Cookies.get(`${app}-is-login`) || Cookies.get(`${app}-info`);

    if (isLogin && !Cookies.get(`${app}-is-login`)) {
      Cookies.set(`${app}-is-login`, '1', {
        domain: parseDomain(window.location.origin),
        secure: true,
      });
    }

    if (!isLogin) {
      return Promise.resolve();
    }

    return fetchProfile({ app });
  });

  // 集团付费会员
  const { data: plusMember, isLoading: plusMemberLoading } = usePlusMember({ userId: user.data?.user_id });

  // 访客
  const visitor = useSWR<any>(
    ['visitor', location.pathname],
    () => {
      const query = parse(window.location.search) as { ee?: string };

      let visitorEmail;
      if (query.ee) {
        try {
          visitorEmail = atob(query.ee);
          sessionStorage.setItem('visitor', visitorEmail);
        } catch (err: any) {
          console.error('parse visitor email from query error', err.message);
        }
      } else {
        visitorEmail = sessionStorage.getItem('visitor');
      }

      return { email: visitorEmail };
    },
    {
      revalidateOnMount: true,
    },
  );

  return (
    <UserContext.Provider
      value={{
        isLoading: user.isLoading || plusMemberLoading || visitor.isLoading,
        plusMember: plusMember?.data?.list?.length === 0 ? undefined : plusMember?.data,
        isVisitor: visitor.data?.email === user.data?.email ? false : !!visitor.data?.email,
        visitor: visitor.data?.email === user.data?.email ? undefined : visitor.data,
        user: user.data,
        refetch: user.mutate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
