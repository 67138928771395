/* eslint-disable react/jsx-filename-extension */
const React = require('react');
const { AppProvider } = require('./src/components/AppProvider');
require('core-js/es/object/from-entries');

exports.onRouteUpdate = ({ prevLocation }) => {
  sessionStorage.setItem('prevLocation', prevLocation ? prevLocation.pathname : '');
};

exports.wrapPageElement = ({ element, props }) => <AppProvider {...props}>{element}</AppProvider>;
