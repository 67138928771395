import { PlusMemberResponse } from '@/types/response/subsrv';
import { Profile } from '@/types/response/user';
import React from 'react';

export const UserContext = React.createContext<{
  user?: Profile;
  visitor?: { email: string };
  isLoading: boolean;
  isVisitor: boolean;
  refetch: () => void;
  plusMember?: PlusMemberResponse['data'];
}>({
  user: undefined,
  visitor: undefined,
  isVisitor: false,
  isLoading: true,
  refetch: () => {},
  plusMember: undefined,
});
