const nodeFetch = require('node-fetch');
const { FetchLogger } = require('./logger');

const fetch = async (url, params = {}) => {
  const fetchLogger = new FetchLogger({ url });

  fetchLogger.requestLog(params);

  const timestamp = Date.now();

  const response = await nodeFetch(url, {
    ...params,
    signal: AbortSignal.timeout(12000),
  });

  fetchLogger.responseLog(response);

  fetchLogger.info(`time: ${Date.now() - timestamp}ms`);

  return response;
};

module.exports = {
  fetch,
};
