const prodConfig = require('./appConfig/prod');
const devConfig = require('./appConfig/dev');
const { LOCALE_ASCRIPTION_MAP, APP_ASCRIPTION_MAP, prodEufyEuApps, devEufyEuApps } = require('./appConfig/eufyEu');

const isProduction = process.env.ENVIRONMENT === 'production';

const BRAND_LOCALE_ASCRIPTION_MAP = {
  eufy: LOCALE_ASCRIPTION_MAP,
};

const BRAND_APP_ASCRIPTION_MAP = {
  eufy: APP_ASCRIPTION_MAP,
};

const BRAND_ASCRIPTION_APPS = {
  eufy: process.env.ENVIRONMENT === 'production' ? prodEufyEuApps : devEufyEuApps,
};

const ascriptionStores = BRAND_ASCRIPTION_APPS[process.env.BRAND] || [];

const storeAccessTokens = JSON.parse(process.env.STOREFRONT_TOKEN || '{}');

ascriptionStores.forEach((item) => {
  const ascriptionLocale = LOCALE_ASCRIPTION_MAP[item.app];
  if (ascriptionLocale) {
    storeAccessTokens[item.country] = storeAccessTokens[ascriptionLocale];
  }
});

module.exports = {
  stores: (isProduction ? prodConfig : devConfig).concat(ascriptionStores),
  storeAccessTokens,
  ascriptionStores,
  LOCALE_ASCRIPTION_MAP: BRAND_LOCALE_ASCRIPTION_MAP[process.env.BRAND] || {},
  APP_ASCRIPTION_MAP: BRAND_APP_ASCRIPTION_MAP[process.env.BRAND] || {},
};
